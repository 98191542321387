import { Australia, Sweden, Switzerland, Usa, Luxembourg, Italy} from '../images/Reviews/flags';
import { AustraliaUser, SwedenUser, SwitzerlandUser, UsaUser, LuxembourgUser, ItalyUser} from '../images/Reviews/userFlags';
import FullStars from '../images/Reviews/FullStars.png';
import HalfStars from '../images/Reviews/HalfStars.png';

const dataForReviews = [
  {
    key: 1,
    img: Usa,
    userImg: UsaUser,
    starImg: FullStars,
    title1: 'Digital Agency,',
    title2: 'founder',
    totalScore: '5.0',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '5.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '5.0'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: "«They are an outstanding, trustworthy partner.»",
    description: 'The OSSystem team set timelines to track the progress of the project and constantly communicated with the company at every stage. The company is most impressed by the team\'s confidence in their capabilities and don\'t resort to cheap sales tactics to boost their metrics.'
  },
  {
    key: 2,
    img: Usa,
    userImg: UsaUser,
    starImg: FullStars,
    title1: 'AI-Based Startup,',
    title2: 'ceo and co-founder',
    totalScore: '5.0',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '4.5'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '4.5'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: "«The quality of communication and the quality of the work are the two big things for me.»",
    description: 'Surpassing both technical and management expectations, OSSystem has seen their role as a continuous partner expand. Moving from one to three resources, they’ve proven themselves to be flexible and adaptive. Customers can expect a transparent partner that integrates smoothly with existing teams. '
  },
  {
    key: 3,
    img: Luxembourg,
    userImg: LuxembourgUser,
    starImg: HalfStars,
    title1: 'Linkfacts S.A.,',
    title2: 'ceo',
    totalScore: '4.5',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '4.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '5.0'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '4.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '4.5'
      },
    ],
    header: '«The capacity to act to unforeseen problems impressed us.»',
    description: 'The launch of the project was successful, meeting the expectations of the internal team. OSSystem establishes an effective workflow through communication and collaboration. The team is hard-working, attentive, and dedicated.'
  },
  {
    key: 4,
    img: Usa,
    userImg: UsaUser,
    starImg: FullStars,
    title1: 'Biotech Company,',
    title2: 'Head of Integrated Data Sciences',
    totalScore: '5.0',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '5.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '5.0'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: '«This is the best experience I\'ve had so far with software developers.»',
    description: 'OSSystem’s efforts have been met with praise and appreciation. Knowledgeable and attentive, the team truly contributes and meets their goals. Customers can expect an astute task force that’s reasonably priced.'
  },
  {
    key: 5,
    img: Italy,
    userImg: ItalyUser,
    starImg: FullStars,
    title1: 'Starsystem IT,',
    title2: 'ceo',
    totalScore: '5.0',
    marks: [
      {
        key: 1,
        param: 'reviews.quality',
        value: '5.0'
      },
      {
        key: 2,
        param: 'reviews.shedule',
        value: '5.0'
      },
      {
        key: 3,
        param: 'reviews.cost',
        value: '5.0'
      },
      {
        key: 4,
        param: 'reviews.refer',
        value: '5.0'
      },
    ],
    header: '«OSSystem’s team was serious, professional, and highly skilled.»',
    description: 'The app is still underway, but it has received praise in user testing. OSSystem delivered high-quality development work on time and at great value. Simple communication, accessibility, and technical expertise made for a smooth workflow.'
  },

];

export default dataForReviews;
