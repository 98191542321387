import { MegazoneLogo, RealtyLogo, UtilitiumLogo, LoveDateLogo } from '../images/Portfolio/logos';
import { MegazoneVideo, RealtyVideo, UtilitiumVideo, LoveDateVideo } from '../images/Portfolio/video';
import { MegazoneIcon, RealtyIcon, UtilitiumIcon, LoveDateIcon } from '../images/Portfolio/backgrounds';

const dataForPortfolio = [
  {
    key: 1,
    logo: MegazoneLogo,
    video: MegazoneVideo,
    background: MegazoneIcon,
    projectClass: 'megazone',
    animationDirection: 'slide-right',
    text: [
      {
        key: 1,
        title: 'projects.megazone.name.title',
        description: 'projects.megazone.name.description'
      },
      {
        key: 2,
        title: 'projects.megazone.platform.title',
        description: 'projects.megazone.platform.description'
      },
      {
        key: 3,
        title: 'projects.megazone.technology.title',
        description: 'projects.megazone.technology.description'
      },
      {
        key: 4,
        title: 'projects.megazone.team.title',
        description: [
          {
            key: 1,
            text: 'projects.megazone.team.description1',
          },
          {
            key: 2,
            text: 'projects.megazone.team.description2',
          },
          {
            key: 3,
            text: 'projects.megazone.team.description3',
          },
          {
            key: 4,
            text: 'projects.megazone.team.description4',
          },
          {
            key: 5,
            text: 'projects.megazone.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.megazone.duration.title',
        description: 'projects.megazone.duration.description'
      },
      {
        key: 6,
        title: 'projects.megazone.client.title',
        description: 'projects.megazone.client.description'
      },
    ]
  },
  {
    key: 2,
    logo: LoveDateLogo,
    video: LoveDateVideo,
    background: LoveDateIcon,
    projectClass: 'loveDate',
    animationDirection: 'slide-left',    
    text: [
      {
        key: 1,
        title: 'projects.loveDate.name.title',
        description: 'projects.loveDate.name.description'
      },
      {
        key: 2,
        title: 'projects.loveDate.platform.title',
        description: 'projects.loveDate.platform.description'
      },
      {
        key: 3,
        title: 'projects.loveDate.technology.title',
        description: 'projects.loveDate.technology.description'
      },
      {
        key: 4,
        title: 'projects.loveDate.team.title',
        description: [
          {
            key: 1,
            text: 'projects.loveDate.team.description1',
          },
          {
            key: 2,
            text: 'projects.loveDate.team.description2',
          },
          {
            key: 3,
            text: 'projects.loveDate.team.description3',
          },
          {
            key: 4,
            text: 'projects.loveDate.team.description4',
          },
          {
            key: 5,
            text: 'projects.loveDate.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.loveDate.duration.title',
        description: 'projects.loveDate.duration.description'
      },
      {
        key: 6,
        title: 'projects.loveDate.client.title',
        description: 'projects.loveDate.client.description'
      },
    ]
  },
  {
    key: 3,
    logo: RealtyLogo,
    video: RealtyVideo,
    background: RealtyIcon,
    projectClass: 'realty',
    animationDirection: 'slide-right',
    text: [
      {
        key: 1,
        title: 'projects.superRealty.name.title',
        description: 'projects.superRealty.name.description'
      },
      {
        key: 2,
        title: 'projects.superRealty.platform.title',
        description: 'projects.superRealty.platform.description'
      },
      {
        key: 3,
        title: 'projects.superRealty.technology.title',
        description: 'projects.superRealty.technology.description'
      },
      {
        key: 4,
        title: 'projects.superRealty.team.title',
        description: [
          {
            key: 1,
            text: 'projects.superRealty.team.description1',
          },
          {
            key: 2,
            text: 'projects.superRealty.team.description2',
          },
          {
            key: 3,
            text: 'projects.superRealty.team.description3',
          },
          {
            key: 4,
            text: 'projects.superRealty.team.description4',
          },
          {
            key: 5,
            text: 'projects.superRealty.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.superRealty.duration.title',
        description: 'projects.superRealty.duration.description'
      },
      {
        key: 6,
        title: 'projects.superRealty.client.title',
        description: 'projects.superRealty.client.description'
      },
    ]
  },
  {
    key: 4,
    logo: UtilitiumLogo,
    video: UtilitiumVideo,
    background: UtilitiumIcon,
    projectClass: 'Utilitium',
    animationDirection: 'slide-right',
    text: [
      {
        key: 1,
        title: 'projects.Utilitium.name.title',
        description: 'projects.Utilitium.name.description'
      },
      {
        key: 2,
        title: 'projects.Utilitium.platform.title',
        description: 'projects.Utilitium.platform.description'
      },
      {
        key: 3,
        title: 'projects.Utilitium.technology.title',
        description: 'projects.Utilitium.technology.description'
      },
      {
        key: 4,
        title: 'projects.Utilitium.team.title',
        description: [
          {
            key: 1,
            text: 'projects.Utilitium.team.description1',
          },
          {
            key: 2,
            text: 'projects.Utilitium.team.description2',
          },
          {
            key: 3,
            text: 'projects.Utilitium.team.description3',
          },
          {
            key: 4,
            text: 'projects.Utilitium.team.description4',
          },
          {
            key: 5,
            text: 'projects.Utilitium.team.description5',
          },
        ]
      },
      {
        key: 5,
        title: 'projects.Utilitium.duration.title',
        description: 'projects.Utilitium.duration.description'
      },
      {
        key: 6,
        title: 'projects.Utilitium.client.title',
        description: 'projects.Utilitium.client.description'
      },
    ]
  },
];

export default dataForPortfolio;
