import React from 'react';
import axios from 'axios';
import authHeader from '../../helpers/getEmailHeader';

import { withTranslation } from 'react-i18next';

import NumberFormat from 'react-number-format';

import {WhatsApp,Viber,Telegram} from '../../images/Qr';

import './FlowForm.scss';

class FlowForm extends React.Component {
  static emptyTimeout;
  static reAppearTimeout;

  constructor(props) {
    super(props)
    this.state = {
      isShow: '',
    }
  }

  componentDidMount() {
    this.timer();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  timer = () => {
    setTimeout(() => this.setState({ isShow: 'message' }), 30000);
  }


  changeShow = (str) => {
    this.setState({
      isShow: str,
    })
  }


  render() {
    const {isShow} = this.state;
    const { t } = this.props;

    const messageIcon = () =>{
      return(
        <div className={'flow-form-container-icon'} onClick={() => this.changeShow('messangers')}>
          <div className='flow-form-wrapper'>
            <span className='icon-message'/>
          </div>
        </div>
      )
    }
    
    const wrapper = (change) => { 
        return (
          <div className="wrapper-content">
            <div
              className="cross-circle"
              onClick={() => this.changeShow("messageIcon")}
            >
              <span className="icon-cross" />
            </div>
            <div className={"flow-form-container"}>
              <div className="flow-form-wrapper">
                {change ? (
                  <React.Fragment>
                    <span
                      className="icon-call"
                      onClick={() => this.changeShow("number")}
                    />
                    <a
                      className="icon-whatsApp"
                      href="https://api.whatsapp.com/send/?phone=380952794212&text&type=phone_number&app_absent=0"
                      target="_blank"
                      onClick={() => this.changeShow("whatsApp")}
                    />
                    <a
                      className="icon-viber"
                      href="viber://chat?number=%2B380952794212"
                      target="_blank"
                      onClick={() => this.changeShow("viber")}
                    />
                    <a
                      className="icon-telegram"
                      href="https://t.me/OSsystem_Apps"
                      target="_blank"
                      onClick={() => this.changeShow("telegram")}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className="icon-arrow" onClick={() => this.changeShow("messangers")}/>
                    <span className="icon-callWhite" />
                    <a href='tel:+1 855 553 57 77' className='footer-contact-link phone-link' id='footerSocial-phone-link'>
                      <span className="number">+1 855 553 57 77</span>
                    </a>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        );
    }

    const message = () => {
      return (
        <div className="wrapper-content">
          <div className="cross-circle" onClick={() => this.changeShow("messageIcon")}>
            <span
              className="icon-cross"
            />
          </div>
          <div
            className={"flow-form-container"}
            onClick={() => this.changeShow("messangers")}
          >
            <div className="flow-form-wrapper">
              <span className="form-title">{t("flow.formTitle")}</span>
              <span className="icon-message" />
            </div>
          </div>
        </div>
      );
    }

    const wrapperMessanger = (icon,image) => {
      return (
        <div className="wrapper-content-qr">
          <div
            className="cross-circle"
            onClick={() => this.changeShow("messageIcon")}
          >
            <span className="icon-cross" />
          </div>
          <div className={"flow-form-container-qr"}>
          <span className="icon-arrow" onClick={() => this.changeShow("messangers")}/>
            <div className="flow-form-wrapper">
              <span className={'icon-' + icon} />
              <span className="text">scan qr code</span>
              <div className="image-box">
                <img src={image} alt="Qr code" className="qr-image" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    
    switch(isShow){
      case 'messageIcon' : return messageIcon()
      case 'message'     : return message()
      case 'messangers'  : return wrapper(true)
      case 'number'      : return wrapper(false)
      case 'whatsApp'    : return wrapperMessanger('whatsApp',WhatsApp)
      case 'viber'       : return wrapperMessanger('viber',Viber)
      case 'telegram'    : return wrapperMessanger('telegram',Telegram)
      default            : return null
    }

  }
};

export default withTranslation()(FlowForm);
