import React, { useEffect, useState } from 'react';

import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import '../../localisation/i18n';

import Logo from '../../images/Logo'

import './NavBar.scss';

export default function NavBar({ location }) {

  const { t, i18n } = useTranslation();

  const getScroll = () => {
    if (!location.state) {
      return 0;
    } else {
      return location.state.scroll;
    }
  }

  const [link, setLink] = useState('/');
  const [currentScroll, setCurrentScroll] = useState();
  const [bgClass, setBgClass] = useState(getScroll() < 100 ? 'navbar-container' : 'navbar-container active');

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return function clean() {
      window.removeEventListener('scroll', updateScroll);
    }
  });

  useEffect(() => {
    if (!isEnglish()) {
      setLink('/en');
    }
  }, [i18n])

  useEffect(() => {
    setCurrentScroll(window.scrollY);
    if (window.scrollY > 100) {
      setBgClass('navbar-container active')
    }
  }, [])

  const isEnglish = () => {
    let path = window.location.pathname;
    return (/(\/en)/g).test(path);
  }

  const updateScroll = () => {
    let verticalScroll = window.scrollY;
    if (verticalScroll > 100) {
      setBgClass('navbar-container active')
    } else {
      setBgClass('navbar-container')
    }
    setCurrentScroll(window.scrollY);
  }

  return (
    <div className={`${bgClass} container-fluid`}>
      <div className='navbar-wrapper container-fluid'>
        <div className='row justify-content-between align-items-center'>
          <div className='logo-container align-items-center justify-content-md-start justify-content-between'>
            <a href='https://os-system.com' target='_blank' rel='noreferrer' className='logo-link'>
              <Logo svgClass='logo-img' />
            </a>
            { /*<Link to={link} state={{ scroll: currentScroll }} className='lang-code'>{t('nextLang')}</Link>*/ }
          </div>
          <div className='link-container d-flex align-items-center justify-content-end'>
            <ScrollLink
              to='services'
              className='navbar-link mr-4'
              smooth={true}
              duration={500}
            >
              {t('navBar.firstLink')}
            </ScrollLink>
            <ScrollLink
              to='projects'
              className='navbar-link mr-4'
              smooth={true}
              duration={500}
            >
              {t('navBar.secondLink')}
            </ScrollLink>
            <ScrollLink
              className='navbar-link mr-5'
              to='reviews'
              smooth={true}
              duration={500}
            >
              {t('navBar.thirdLink')}
            </ScrollLink>
            <ScrollLink
              className='navbar-link navbar-button'
              to='contact-us'
              smooth={true}
              duration={500}
              id="contact-button"
            >
              {t('navBar.button')}
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  )
}
