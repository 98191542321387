import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link as ScrollLink } from 'react-scroll';

import DesktopPhone from '../../images/Header/DesktopPhone';
import MobilePhone from '../../images/Header/MobilePhone';

import '../../style/animation/DesktopPhoneAnimation.scss';
import './Header.scss';

export default function Header() {

  const { t } = useTranslation();

  return (
    <div className='header-container container-fluid'>
      <div className='header-wrapper container'>
        <div className='header-main row justify-content-md-between justify-content-sm-center'>
          <div className='header-main-titleBlock col-md-5 text-center text-md-left col-sm-10'>
            <h2 className='header-suptitle '>{t('header.suptitle1')}<br />{t('header.suptitle2')}</h2>
            <h1 className='header-title'>{t('header.title1')}<br />{t('header.title2')}</h1>
            <ScrollLink
              className='header-button'
              to='contact-us'
              smooth={true}
              duration={500}
              id="quote-button"
            >
              {t('header.button')}
            </ScrollLink>
          </div>
          <div className='header-graphic col-lg-6 col-md-6'>
            <DesktopPhone svgClass='desktop-phone d-lg-block d-none' />
            <MobilePhone svgClass='mobile-phone d-none d-md-block d-lg-none' />
          </div>
        </div>
        <div className='header-number-container container p-0'>
          <div className='row no-gutters justify-content-between text-lg-center'>
            <div className='single-number d-flex align-items-center col-lg-3'>
              <span className='single-number-rate'>15+</span>
              {t('header.15+')}
            </div>
            <div className='single-number d-flex align-items-center col-lg-3'>
              <span className='single-number-rate'>40+</span>
              {t('header.40+')}
            </div>
            <div className='single-number d-flex align-items-center col-lg-3'>
              <span className='single-number-rate'>120+</span>
              {t('header.120+')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
