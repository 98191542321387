import React from 'react';
import { useTranslation } from 'react-i18next';

import EnChartDesktop from '../../images/Flow/Graphic/EnChartDesktop.svg';
import EnChartMobile from '../../images/Flow/Graphic/EnChartMobile.svg';

import './FlowComponent.scss';

export default function FlowComponent() {

  const { t } = useTranslation();

  return (
    <div className='flow-container container-fluid' id='services'>
      <div className='flow-wrapper container'>
        <h3 className='flow-suptitle row justify-content-center'>{t('flow.suptitle')}</h3>
        <h2 className='flow-title row justify-content-center'>{t('flow.title')}</h2>
        <div className='flow-graphic-container row justify-content-center'>
          <img src={EnChartDesktop} alt='Development flow chart' className='flow-graphic col-12 col-lg-10 d-none d-md-block' />
          <img src={EnChartMobile} alt='Development flow chart' className='flow-graphic col-12 d-block d-md-none' />
        </div>
      </div>
    </div>
  )
}
